<script setup>
import { ref,inject,onMounted,provide  } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios';
import { useMemberStore } from '../../stores';
const userStore = useMemberStore();
const baseURL = 'https://mini.walkertour.cn';
const rootPath = "/www/wwwroot/miniServer/server/dist";

const billid = ref(1);//inject('billid');
const size = ref('default')
const labelPosition = ref('right')
const imageUrl = ref('')
const headers = ref({
	'Authorization': `${userStore.profile?.token}`,
})

const sizeForm = ref({
	lx:'',//接送机类型
	ydrxm:'',//预定人姓名
	sjh:'',//手机号
	email:'',//邮箱
	ydsj:'',//预定时间
	jc:'',//机场
	cx:'',//车型
	cfd:'',//出发地
	mdd:'',//目的地
	ckrs:'',//乘客人数
	ydts:'',//预定天数
	hbh:'',//航班号
	tsxq:'',//特殊需求
	bz:'',//备注
	zj:''//总价
});
onMounted(async()=>{

})
const onSubmit=async()=> {
	let isNull = false;
	Object.keys(sizeForm.value).forEach(key=>{
		if(sizeForm.value[key]===''){
			isNull = true;
		}
	})
	if(!isNull){
		const response = await axios.post('/manageOrder/order/add', {
		  ...sizeForm.value
		}, {
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage.success('添加成功!')
			sizeForm.value = {}
		}
	}else{
		ElMessage.error('所有信息不可为空!')
	}
}
const handleAvatarSuccess= (
  response,
  uploadFile
) => {
  if(response.status===0){
  	imageUrl.value = response.imgUrl.replace(rootPath,baseURL);
  	sizeForm.value.logo = response.imgUrl;
  }
}

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.type !== 'image/jpeg'&&rawFile.type !== 'image/png') {
    ElMessage.error('Avatar picture must be JPG/PNG format!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('Avatar picture size can not exceed 2MB!')
    return false
  }
  return true
}
</script>
<template>
	<el-form
	    ref="form"
	    :model="sizeForm"
	    label-width="auto"
	    :label-position="labelPosition"
	    :size="size"
	>
		<div style="display: flex;">
			<el-form-item label="预订人姓名" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.ydrxm" />
			</el-form-item>
			<el-form-item label="服务类型" style="width: 48%;">
			  <el-radio-group v-model="sizeForm.lx">
				<el-radio border label="接机" value="接"/>
				<el-radio border label="送机" value="送"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="手机号" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.sjh" />
			</el-form-item>
			<el-form-item label="邮箱" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.email" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="使用时间" style="width: 92%;">
			  <el-date-picker
				  v-model="sizeForm.ydsj"
				  type="datetime"
				  placeholder="选择日期"
				  size="default"
				  format="YYYY-MM-DD HH:mm:ss"
				  value-format="YYYY-MM-DD HH:m:s"
				/>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="机场" style="width: 48%;">
			  <el-radio-group v-model="sizeForm.jc">
				<el-radio border label="仁川" value="仁川"/>
				<el-radio border label="金浦" value="金浦"/>
				<el-radio border label="釜山" value="釜山"/>
				<el-radio border label="济州" value="济州"/>
			  </el-radio-group>
			</el-form-item>
			<el-form-item label="航班号" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.hbh" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="车型" style="width: 92%;">
			  <el-radio-group v-model="sizeForm.cx">
				<el-radio border label="舒5" value="舒5"/>
				<el-radio border label="VIP" value="VIP"/>
				<el-radio border label="舒9" value="舒9"/>
			  </el-radio-group>
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="出发地" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.cfd" />
			</el-form-item>
			<el-form-item label="目的地" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.mdd" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="乘客人数" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.ckrs" />
			</el-form-item>
			<el-form-item label="预定天数" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.ydts" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="特殊需求" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.tsxq" />
			</el-form-item>
			<el-form-item label="备注" style="width: 48%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.bz" />
			</el-form-item>
		</div>
		<div style="display: flex;">
			<el-form-item label="支付总价格" style="width: 92%;">
			  <el-input class="w-50 m-2" v-model="sizeForm.zj" />
			</el-form-item>
		</div>
		<div style="display: flex;justify-content: center;">
			<el-form-item>
		      <el-button type="primary" @click="onSubmit">保存</el-button>
		      <el-button>重置</el-button>
		    </el-form-item>
		</div>
	</el-form>
</template>

<style scoped>
	.avatar-uploader .avatar {
	  width: 90px;
	  height: 90px;
	  display: block;
	}
	.avatar-uploader .el-upload {
	  border: 1px dashed var(--el-border-color);
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	  transition: var(--el-transition-duration-fast);
	}
	
	.avatar-uploader .el-upload:hover {
	  border-color: var(--el-color-primary);
	}
	
	.el-icon.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 90px;
	  height: 90px;
	  text-align: center;
	}
</style>